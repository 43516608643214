import React from "react";

const Auth = React.lazy(() => import("../views/Auth/Auth"));

const authRoutes = [
  {
    path: "/auth",
    component: Auth,
  },
];

export default authRoutes;
