import React from "react";

const Dashboard = () => {
  return (
    <div className="flex__centerlize" style={{ minHeight: 400 }}>
      <p>الداشبور... إذا بتخطط تسويها لاحقاً</p>
    </div>
  );
};

export default Dashboard;
