import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    initImmediate: false, //Synchronously
    supportedLngs: ["ar"],
    preload: ["ar"],
    // lng: "ar", //This will make the call "Async" and initial i18.language will be undefined (even with passing initImmediate)
    fallbackLng: "ar",
    ns: [
      "common",
      "auth",
      "category",
      "product",
      "coupon",
      "page",
      "user",
      "cities",
      "setting",
      "order",
    ],
    defaultNS: "common",
    // debug: true,
    // interpolation: {
    //   escapeValue: false,
    // },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      // useSuspense: false,
    },
  });

export default i18n;
