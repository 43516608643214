import { useMemo, useState } from "react";
import styled from "@emotion/styled";
import AppBar from "./AppBar";
import SideDrawer from "./SideDrawer";
import { DRAWER_WIDTH } from "../../util/constants";
import { createCookie, readCookie } from "../../util/cookies";

interface LayoutProps {
  children: JSX.Element;
}
const Main = styled.main(({ theme }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(9),
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    // paddingLeft: theme.spacing(8),
  },
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeInOut,
    duration: 500,
  }),
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: 65,
  },
  "&.opened": {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: DRAWER_WIDTH,
    },
  },
  "& > .container": {
    padding: 0,
  },
}));
const Layout = ({ children }: LayoutProps) => {
  const initDrawerVal = useMemo(() => {
    const drawerCookie = readCookie("drawer");
    const initDrawer = drawerCookie ? +drawerCookie > 0 : window.innerWidth > 790;
    return initDrawer;
  }, []);
  const [drawer, setDrawer] = useState(initDrawerVal);
  const toggleDrawer = () => {
    setDrawer((prev) => {
      createCookie("drawer", prev ? "0" : "1", 354);
      return !prev;
    });
  };

  return (
    <>
      <SideDrawer open={drawer} toggleDrawer={toggleDrawer} />
      <AppBar open={drawer} toggleDrawer={toggleDrawer} />
      <Main id="main" className={drawer ? "opened" : ""}>
        {children}
      </Main>
    </>
  );
};

export default Layout;
