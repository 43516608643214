import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import FullPageSuspense from "./components/Spinners/FullPageSuspense";
import AuthProvider from "./context/auth/state";
import SnackBarProvider from "./context/snakbar/state";
import ThemeProviderWithCache from "./theme/Provider";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProviderWithCache>
      <Suspense fallback={<FullPageSuspense />}>
        <BrowserRouter>
          <AuthProvider>
            <SnackBarProvider>
              <App />
            </SnackBarProvider>
          </AuthProvider>
        </BrowserRouter>
      </Suspense>
    </ThemeProviderWithCache>
  </React.StrictMode>,
  document.getElementById("root")
);
