import React from "react";

const List = React.lazy(() => import("../views/Coupons/List"));
const AddEdit = React.lazy(() => import("../views/Coupons/AddEdit"));

const couponRoutes = [
  {
    path: "/coupons",
    component: List,
  },
  {
    path: "/coupons/add",
    component: AddEdit,
  },
  {
    path: "/coupons/:id",
    component: AddEdit,
  },
];

export default couponRoutes;
