import { GlobalStyles } from "@mui/material";
import { initialTheme } from "./theme";

export const GLOBALCSS = (
  <GlobalStyles
    styles={{
      body: {
        background: initialTheme.palette.background.default,
      },
      /** Selection */
      "body ::selection": {
        background: initialTheme.palette.primary.main,
        color: initialTheme.palette.primary.contrastText,
      },
      "body ::-moz-selection": {
        background: initialTheme.palette.primary.main,
        color: initialTheme.palette.primary.contrastText,
      },
      /**Scrollbar */
      "& .slim_scrollbar": {
        "::-webkit-scrollbar": {
          width: "0.8em",
        },
        "::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3);",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: initialTheme.palette.primary.light,
          // borderRadius: 5,
        },
      },
      "& .slimmer": {
        "::-webkit-scrollbar": {
          width: "0.4em",
        },
      },
      /**General */
      "html, body, main": {
        minHeight: "77vh",
        lineHeight: "1.7",
      },
      "&:focus-visible": {
        outline: `auto ${initialTheme.palette.primary.main} 2px`,
      },
      a: {
        textDecoration: "none",
      },
      ".highlighted": {
        display: "inline",
        boxShadow: `inset 0 -0.5em 0px 0px ${initialTheme.palette.primary.main}`,
      },
      ".center": {
        textAlign: "center",
      },
      ".fullwidth": {
        width: "100%",
      },
      ".fullheight": {
        height: "100%",
      },
      /* Flexize */
      ".flex__centerlize": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      ".flex": {
        display: "flex",
      },
      ".flex-align-center": {
        alignItems: "center",
      },
      ".flex-align-baseline": {
        alignItems: "baseline",
      },
      ".flex-justify-center": {
        justifyContent: "center",
      },
      ".flex-justify-between": {
        justifyContent: "space-between",
      },
      ".flex-justify-evenly": {
        justifyContent: "space-evenly",
      },
      /* Positionize */
      ".position__centerlize": {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
      ".clearp": {
        padding: "0 !important",
      },
      ".hoverable-op": {
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
      },
      /* print */
      ".printonly": {
        visibility: "hidden",
        height: "0",
        position: "absolute",
        top: "-100%",
        zIndex: -1,
      },
      "@page": {
        size: "A4",
        margin: "2mm 5mm 2mm 5mm",
      },
      "@media print": {
        ".printonly": {
          visibility: "visible",
          position: "relative",
          height: "auto",
          top: "auto",
          zIndex: "auto",
        },
        ".noprint": {
          display: "none",
        },
        "html, body": {
          width: "210mm",
          height: "297mm",
        },
        ".print-footer": {
          display: "table-footer-group",
          position: "fixed",
          bottom: "0",
          left: "0",
          top: "auto",
          width: "100%",
        },
        "table thead": {
          display: "table-header-group",
        },
        " table tfoot": {
          display: "table-footer-group",
        },
      },
    }}
  />
);
