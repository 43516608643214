import styled from "@emotion/styled";
import { ErtiqaLogo } from "../SVGs/Logo";

const FullContainer = styled.div(({ theme }) => ({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: `linear-gradient(180deg, ${theme.palette.primary.main} 90%, ${theme.palette.primary.light} 150%)`,
  "& > svg": {
    padding: 25,
  },
}));

const FullPageSuspense = () => {
  return (
    <FullContainer>
      <ErtiqaLogo width={200} />
    </FullContainer>
  );
};

export default FullPageSuspense;
