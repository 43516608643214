import { Alert, Slide, SlideProps, Snackbar, SnackbarProps, Typography } from "@mui/material";
import { ReactChild, SyntheticEvent, useState } from "react";
import { SnackBar, SnackBarState } from "./context";

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="down" />;
};

const SnackBarProvider = ({ children }: { children: ReactChild }) => {
  const [state, setState] = useState<SnackBarState>({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 8000,
  });

  const fireSnack = (options: SnackbarProps) => {
    setState((prev) => ({ open: true, autoHideDuration: 8000, severity: "success", ...options }));
  };
  const dismiss = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setState((prev) => ({ ...prev, open: false }));
  };

  return (
    <SnackBar.Provider
      value={{
        fireSnack,
      }}
    >
      <Snackbar
        open={state.open && !!state.message}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={state.autoHideDuration}
        sx={{ width: 400, maxWidth: "95%" }}
        onClose={dismiss}
      >
        <Alert
          onClose={dismiss}
          severity={state.severity}
          variant="filled"
          sx={{ width: "100%" }}
          action={state.action}
        >
          <Typography>{state.message}</Typography>
        </Alert>
      </Snackbar>
      {children}
    </SnackBar.Provider>
  );
};

export default SnackBarProvider;
