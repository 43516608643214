import { forwardRef } from "react";
import { SVGProps } from "./types";

export const ErtiqaLogo = forwardRef<SVGSVGElement, SVGProps>((props, ref) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 154.85 60.35"
    {...props}
    width={props.width || 50}
    height={props.height || props.width || 50}
    fill={props.color || "inherit"}
    ref={ref}
  >
    <g>
      <path
        style={{ fill: "#fff" }}
        d="M83.7,38.63v12c0,4.28-1.5,6.87-4.54,7.92l-.25,0a13.55,13.55,0,0,0,2.14-8v-12Z"
      />
      <path
        style={{ fill: "#fff" }}
        d="M73.23,45.47l-.81.55-.39.26a5.38,5.38,0,0,1-5.73,5.07H61.49l1-1c2-2,4.26-5.1,4.26-8.13a7.64,7.64,0,1,0-15.28,0c0,3,2.31,6.09,4.26,8.13l1,1H51.47c-3.71,0-6-2.43-6.22-6.66V30.49H42.64V44.35l0,.18C43,51,45.47,54,50.44,54H66.93c5,0,7.5-3.06,7.78-9.61C74.22,44.74,73.66,45.17,73.23,45.47ZM59.5,49.56l-.43.41-.43-.42c-1.38-1.32-4.58-4.71-4.58-7.43a5,5,0,1,1,10,0C64.08,44.85,60.88,48.23,59.5,49.56Z"
      />
      <rect style={{ fill: "#fff" }} x="90.3" y="30.49" width="2.63" height="23.34" />
      <path
        style={{ fill: "#fff" }}
        d="M34.12,48v4.17h4.17V54H31.67a1.88,1.88,0,0,1-2.05-1.82H33l-.64-.93a3.29,3.29,0,0,1-.75-2.36v-3h3.45c2.62,0,3.57,1.5,3.57,2.5v.35A7.39,7.39,0,0,0,35.06,48Z"
      />
      <path
        style={{ fill: "#fff" }}
        d="M90.93,57.54h.38a3.72,3.72,0,0,1,1.57.25l.19.07,0-.2c0-.4-.43-1.08-1.66-1.08h-1.6V58a1.53,1.53,0,0,0,.28,1l.37.57H88.86l0,.16a.83.83,0,0,0,.9.65h3.25v-.81H90.93Z"
      />
      <polygon
        style={{ fill: "#fff" }}
        points="57.09 28.58 58.68 30.17 57.09 31.7 55.53 30.17 57.09 28.58"
      />
      <polygon
        style={{ fill: "#fff" }}
        points="62.59 30.17 61.02 31.7 59.47 30.17 61.02 28.59 62.59 30.17"
      />
      <polygon
        style={{ fill: "#fff" }}
        points="71.1 38.5 72.69 40.08 71.1 41.61 69.54 40.09 71.1 38.5"
      />
      <polygon
        style={{ fill: "#fff" }}
        points="76.6 40.09 75.03 41.61 73.48 40.08 75.03 38.51 76.6 40.09"
      />
      <polygon
        style={{ fill: "#fff" }}
        points="12.24 20.63 12.09 23.12 0 23.12 0 0.08 11.92 0.08 11.92 2.66 2.75 2.66 2.79 10.37 10.75 10.37 10.75 12.52 2.79 12.52 2.82 20.73 12.24 20.63"
      />
      <path
        style={{ fill: "#fff" }}
        d="M31.86,20.62c-.06-.21-.12-.4-.18-.56s-.12-.34-.16-.43l-1.61-3.82a9.66,9.66,0,0,0-1.33-2.06,3.54,3.54,0,0,0-.91-.71l-1-.52,1-.39a6.49,6.49,0,0,0,2.75-1.92,6.13,6.13,0,0,0,1.36-4,5.15,5.15,0,0,0-2.26-4.67C28.07.57,25.7.08,22.54.08H18.13V4.45L18,23.09h2.91c0-.57-.1-3-.1-3.44V13.19h2.33a4.3,4.3,0,0,1,2.14.47,3.24,3.24,0,0,1,1.21,1.13,11.18,11.18,0,0,1,.83,1.75l1.43,3.7a16.37,16.37,0,0,0,1.77,3.44,6,6,0,0,0,4.25,1.76A9.21,9.21,0,0,1,31.86,20.62Zm-8.57-9.07-2.48,0V1.16l.47,0c.27,0,.72,0,1.46,0a6.63,6.63,0,0,1,4.58,1.33,5.28,5.28,0,0,1,1.44,4,4.22,4.22,0,0,1-1.52,3.45A5.5,5.5,0,0,1,23.29,11.55Z"
      />
      <polygon
        style={{ fill: "#fff" }}
        points="51.59 2.5 45.22 2.5 45.26 23.12 42.47 23.12 42.44 2.5 36.14 2.5 36.14 0.08 51.59 0.08 51.59 2.5"
      />
      <path
        style={{ fill: "#fff" }}
        d="M86,.08H82.15L75.22,23.24H78l2.12-7.08h8.09l2.15,7.08h2.6ZM80.56,14,83.9,1.81,87.71,14Z"
      />
      <path
        style={{ fill: "#fff" }}
        d="M67.44,22.06l-.11-.34.29-.21a9.26,9.26,0,0,0,3.77-7.82c0-6.34-8.46-13-8.55-13.1L62.08,0,61.3.59c-.08.07-8.55,6.75-8.55,13.1a9.14,9.14,0,0,0,9.32,9.57,9.83,9.83,0,0,0,2.77-.41l.35-.1.2.31a1.49,1.49,0,0,0,.15.21,3.84,3.84,0,0,0,3.27,1.62l.51,0A4.84,4.84,0,0,1,67.44,22.06Zm-5.37-1.33c-3.38,0-6.79-2.18-6.79-7,0-4,4.94-8.78,6.46-10.15l.33-.31.34.31c1.51,1.38,6.45,6.18,6.45,10.15C68.86,17.08,66.74,20.73,62.07,20.73Z"
      />
      <path
        style={{ fill: "#fedaa9" }}
        d="M132.06.61,131.27,0l-.8.61A96.41,96.41,0,0,0,117.24,14l0,0-.1.17c-5,6.21-9.42,13.78-9.42,21a25.42,25.42,0,0,0,6.33,17,1.36,1.36,0,0,0,.23.32l.37.31a22.67,22.67,0,0,0,16.65,6.59c15.48,0,23.58-12.19,23.58-24.24C154.85,18.86,132.29.79,132.06.61Zm-18,22.55A21.28,21.28,0,0,0,118,39a21.76,21.76,0,0,0,15.54,9.06A25.27,25.27,0,0,1,131,50.39c-3.41,2.77-10.51,4.37-15,.15l-.06,0c-3.41-3.54-5.67-8.63-5.67-15.4C110.3,31.24,111.8,27.13,114.05,23.16ZM131,36c-4.62-4.61-7.38-7.32-8.68-8.58l9-10c2,2,6.67,7.14,7.15,13.11.42,5.27-.16,9.49-1.9,13.06C135.85,41.78,134.26,39.29,131,36Zm.27,20.71a22.87,22.87,0,0,1-6.88-1.07,16.16,16.16,0,0,0,8.27-3.26c6.71-5.47,9.23-12.08,8.43-22.09-.69-8.67-8.64-15.47-9-15.75l-1-.82L118.71,27.58l.91.87s2.77,2.67,9.53,9.42c4,4,5.09,6.5,5.36,7.66a19.09,19.09,0,0,1-14.43-8.06c-7.26-10-1.81-20.22-.82-21.9a88.7,88.7,0,0,1,12-12.25c3.89,3.27,21,18.41,21,31.78C152.24,50,141.37,56.74,131.27,56.74Z"
      />
    </g>
  </svg>
));
