import React from "react";

const List = React.lazy(() => import("../views/Orders/Index"));
const View = React.lazy(() => import("../views/Orders/View"));

const orderRoutes = [
  {
    path: "/orders",
    component: List,
  },
  {
    path: "/orders/:id",
    component: View,
  },
];

export default orderRoutes;
