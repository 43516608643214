import { AlertProps, SnackbarProps } from "@mui/material";
import { createContext } from "react";

export interface SnackBarState
  extends Pick<SnackbarProps, "message" | "action" | "autoHideDuration">,
    Pick<AlertProps, "severity"> {
  open?: boolean;
}
export interface SnackBarType extends SnackBarState {
  fireSnack(options: Omit<SnackBarState, "open">): void;
}

export const SnackBar = createContext<SnackBarType>({
  fireSnack: () => {},
  open: false,
});
