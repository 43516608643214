import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import i18n from "../../i18n";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styled from "@emotion/styled";
import { DRAWER_WIDTH } from "../../util/constants";
import { ExitToAppRounded } from "@mui/icons-material";
import { useAuth } from "../../hooks/useAuth";

interface HeaderBarProps {
  open: boolean;
  toggleDrawer(): void;
}
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.easeInOut,
    duration: 500,
  }),
  "&.open": {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
  },
  "& .grow": {
    flexGrow: 1,
  },
  "& .menu_icon": {
    transform: "rotateZ(0)",
    opacity: 1,
    width: "auto",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
    "&.open": {
      transform: "rotateZ(-90deg)",
      opacity: 0,
      width: 0,
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeInOut,
        duration: 500,
      }),
    },
  },
  "& .menu_arrow": {
    transform: "rotateZ(90deg)",
    opacity: 0,
    width: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
    }),
    "&.open": {
      transform: "rotateZ(0)",
      opacity: 1,
      width: "auto",
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeInOut,
        duration: 500,
      }),
    },
  },
  "& .top_info": {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
}));
const HeaderBar = ({ open, toggleDrawer }: HeaderBarProps) => {
  const { logout, user } = useAuth();

  const BackIcon = i18n.dir(i18n.language) === "rtl" ? ChevronRightIcon : ChevronLeftIcon;

  return (
    <StyledAppBar className={open ? "open" : ""} position="relative">
      <Toolbar variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => toggleDrawer()}
        >
          <div className="flex">
            <MenuIcon className={`menu_icon ${open ? "open" : ""}`} fontSize="large" />
            <BackIcon className={`menu_arrow ${open ? "open" : ""}`} fontSize="large" />
          </div>
        </IconButton>
        <div className="grow" />
        <div className="top_info">
          <Typography paddingX={1} marginTop={1} variant="h6" noWrap>
            {user?.name || ""}
          </Typography>
          <IconButton color="inherit" onClick={logout}>
            <ExitToAppRounded />
          </IconButton>
        </div>
      </Toolbar>
    </StyledAppBar>
  );
};

export default HeaderBar;
