import styled from "@emotion/styled";
import { CheckCircleRounded, ReportRounded, WarningRounded } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";

const Section = styled.section<Partial<IconTextBlockProps>>(
  ({ theme, color, fontSize, opacity }) => ({
    width: "100%",
    padding: 5,
    textAlign: "center",
    "& .icon": {
      color: theme.palette[color || "primary"].main,
      fontSize: fontSize || 200,
      opacity: opacity || 0.3,
    },
    "& .action_btn": {
      marginTop: 20,
    },
  })
);

interface IconTextBlockProps {
  type?: "success" | "error" | "warning";
  color?: "primary" | "secondary" | "warning" | "error" | "info" | "success";
  text: string;
  onAction?(): void;
  fontSize?: number;
  opacity?: number;
  customIcon?: JSX.Element;
  materialIcon?: any;
  actionText?: string;
  actionIcon?: JSX.Element;
}
const IconTextBlock = ({
  type = "warning",
  color = "primary",
  text,
  onAction,
  fontSize,
  opacity,
  customIcon,
  materialIcon,
  actionText,
  actionIcon,
}: IconTextBlockProps) => {
  const { t } = useTranslation();

  let ICON;
  switch (type) {
    case "success":
      ICON = CheckCircleRounded;
      break;
    case "error":
      ICON = ReportRounded;
      break;
    default:
      ICON = materialIcon || WarningRounded;
  }

  return (
    <Section color={color} fontSize={fontSize} opacity={opacity}>
      {customIcon ? customIcon : <ICON className="icon" />}
      {text && <Typography variant="body2">{text}</Typography>}
      {onAction && (
        <Button
          className="action_btn"
          color={color}
          onClick={onAction}
          variant="outlined"
          startIcon={actionIcon || <CachedRoundedIcon />}
        >
          <Typography>{actionText || t("common:tryagain")}</Typography>
        </Button>
      )}
    </Section>
  );
};

export default IconTextBlock;
