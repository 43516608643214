import { CacheProvider, ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { useCustomTheme } from "../hooks/useTheme";
import i18n from "../i18n";
import createEmotionCache from "./createEmotionCache";
import { GLOBALCSS } from "./globals";

const ThemeProviderWithCache = ({ children }: { children: JSX.Element }) => {
  const dir = i18n.dir(i18n.language);
  const cachedVal = createEmotionCache(dir);
  const { theme } = useCustomTheme();
  return (
    <CacheProvider value={cachedVal}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {GLOBALCSS}
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default ThemeProviderWithCache;
