import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { Theme as MuiTheme } from "@mui/material/styles/createTheme";
import { alpha } from "@mui/material";

// Define custom fields
declare module "@mui/material/styles" {
  interface Theme {}
  interface ThemeOptions {}
}
declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}

type CustomPalette = Record<
  "primary" | "secondary" | "warning" | "info" | "error" | "success",
  any
>;

const palette: Partial<CustomPalette> = {
  primary: {
    main: "#020202",
  },
  secondary: {
    main: "#fedaa9",
  },
  info: {
    main: "#c97567",
  },
  warning: {
    main: "#397f79",
  },
};
const defaultTheme = createTheme({
  palette: {
    ...palette,
    // mode: "dark",
    background: {
      default: "#f3f3f3",
    },
  },
  typography: {
    fontFamily: ["Swissra", "Roboto"].join(","),
    h1: {
      fontFamily: ["Swissra-Bold", "Roboto"].join(","),
      fontSize: 32,
    },
    h2: {
      fontFamily: ["Swissra-Bold", "Roboto"].join(","),
      fontSize: 28,
    },
    h3: {
      fontFamily: ["Swissra-Bold", "Roboto"].join(","),
      fontSize: 24,
    },
    h4: {
      fontFamily: ["Swissra-Bold", "Roboto"].join(","),
      fontSize: 20,
    },
    h5: {
      fontFamily: ["Swissra-Bold", "Roboto"].join(","),
      fontSize: 16,
    },
    h6: {
      fontFamily: ["Swissra-Bold", "Roboto"].join(","),
      fontSize: 14,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
  },
});
const initial: MuiTheme = {
  ...defaultTheme,
  direction: "rtl",
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "Swissra";
        font-display: swap;
        src: local("Swissra"), url(/fonts/Swissra.ttf) format("truetype");
      }
      @font-face {
        font-family: "Swissra-Bold";
        font-display: swap;
        src: local("Swissra-Bold"), url(/fonts/Swissra-Bold.ttf) format("truetype");
      }
      @font-face {
        font-family: "Swissra-Semibold";
        font-display: swap;
        src: local("Swissra-Semibold"), url(/fonts/Swissra-Semibold.ttf) format("truetype");
      }
      @font-face {
        font-family: "Colfax-Regular";
        font-display: swap;
        src: local("Colfax-Regular"), url(/fonts/Colfax-Regular.otf) format("opentype");
      }
      @font-face {
        font-family: "Colfax-Bold";
        font-display: swap;
        src: local("Colfax-Bold"), url(/fonts/Colfax-Bold.otf) format("opentype");
      }
      `,
    },
    MuiTypography: {
      defaultProps: {
        lineHeight: 1.7,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          "& .MuiTypography-root": {
            /** This is to fix somthing weird about Swissra font happens in Linux */
            // marginTop: 5,
          },
        },
      },
      // Because primary color was changed to black
      // will override the hover and active color to make it lighter
      variants: [
        {
          props: { color: "primary", variant: "contained" },
          style: {
            "&:hover": {
              background: "#1e1e1e",
            },
          },
        },
      ],
    },
    MuiIconButton: {},
    MuiChip: {
      styleOverrides: {
        label: {
          /** This is to fix somthing weird about Swissra font happens in Linux */
          // marginTop: 5,
        },
        root: {
          borderRadius: 2,
        },
      },
      // Because primary color was changed to black
      // will override the hover and active color to make it lighter
      variants: [
        {
          props: { color: "primary", variant: "filled" },
          style: {
            "&:hover": {
              background: "#1e1e1e",
            },
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: alpha(palette.primary.main, 0.2),
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: alpha(palette.primary.main, 0.2),
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "0!important",
          background: "#f0f0f0",
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        icon: {
          color: "#C97567",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        filledWarning: {
          color: defaultTheme.palette.warning.contrastText,
        },
        outlinedWarning: {
          color: defaultTheme.palette.warning.contrastText,
        },
        standardWarning: {
          color: defaultTheme.palette.warning.contrastText,
        },
      },
    },
  },
};

export const initialTheme = responsiveFontSizes(initial);
