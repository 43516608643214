import React from "react";

const List = React.lazy(() => import("../views/Cities/List"));

const citiesRoutes = [
  {
    path: "/cities",
    component: List,
  },
];

export default citiesRoutes;
