import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../util/constants";
import { readCookie } from "../util/cookies";

interface useHttpProps {
  upProgress?: boolean;
  preventAuthRedirect?: boolean;
  initLoad?: boolean;
}
type FetchProps = {
  url: string;
  /**
   * urlPrefix added this way due to later on development which made
   * some routes has `/admin` prefix and some doesnt have it
   */
  urlPrefix?: string;
  query?: string;
  method?: "get" | "post" | "put" | "patch" | "delete";
  body?: Record<string, any>;
  upload_id?: string;
  delay?: number;
  withCredentials?: boolean;
  headers?: AxiosRequestConfig["headers"];
};

export type ErrorResponse = {
  message: string;
  errors: Record<string, string | string[]>;
};
type ProgressState = { percentage: number; upload_id: string };
let timeout: Record<string, NodeJS.Timeout> = {};

export const useHttp = (props: useHttpProps = {}) => {
  const { upProgress, preventAuthRedirect, initLoad } = props;
  const [loading, setLoading] = useState(!!initLoad);
  const [errors, setErrors] = useState<ErrorResponse | undefined>();
  const [progress, setProgress] = useState<ProgressState>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const clearErrors = () => {
    if (errors) {
      setErrors(undefined);
    }
  };

  const fetch = async <T>({
    url,
    urlPrefix = "/admin",
    query = "",
    method = "get",
    body,
    upload_id,
    delay,
    withCredentials = true,
    headers,
  }: FetchProps) => {
    try {
      setLoading(true);
      clearErrors();

      const onUploadProgress = (progress: any) => {
        if (upProgress) {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
          setProgress(() => ({
            percentage: parseInt(uploadPercentage.toString()),
            upload_id: upload_id || "",
          }));
        }
      };

      const _url = urlPrefix + url + (query || "");
      const Authorization = withCredentials ? `Bearer ${readCookie("token")}` : "";
      const requestConfig = {
        baseURL: withCredentials ? API : undefined,
        url: _url,
        method,
        data: body,
        headers: {
          Accept: "application/json",
          Authorization,
          locale: i18n.language || "",
          ...headers,
        },
        onUploadProgress,
      };
      if (delay) {
        clearTimeout(timeout[url]);
        await new Promise((resolve) => {
          timeout[url] = setTimeout(() => {
            resolve("");
          }, delay);
        });
      }
      const res: AxiosResponse<T> = await axios(requestConfig);

      return res.data;
    } catch (err: any) {
      const error = err?.response?.data;
      if (err?.response?.status === 401 && !pathname.startsWith("/auth") && !preventAuthRedirect) {
        navigate(`/auth?redirect=${pathname}`);
        // 404 error has different error response - No standardzation of error responses
      } else if (err?.response?.status === 404) {
        setErrors({ message: error?.error?.message, errors: {} });
      } else {
        setErrors(error);
      }
      throw err;
    } finally {
      setLoading(false);
      if (upProgress) {
        setProgress({ percentage: 0, upload_id: "" });
      }
    }
  };

  return { fetch, loading, errors, clearErrors, progress };
};
