import React from "react";
const List = React.lazy(() => import("../views/Pages/List"));
const AddEdit = React.lazy(() => import("../views/Pages/AddEdit"));

const pageRoutes = [
  {
    path: "/pages",
    component: List,
  },
  {
    path: "/pages/add",
    component: AddEdit,
  },
  {
    path: "/pages/:id",
    component: AddEdit,
  },
];

export default pageRoutes;
