export const DRAWER_WIDTH = 240;
export const API = process.env.REACT_APP_API;
export const SITE_DOMAIN = process.env.REACT_APP_SITE_DOMAIN;
export const IMG_MIMES = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/svg+xml",
  "image/tiff",
  "image/bmp",
  "image/webp",
  "png",
  "jpg",
  "jpeg",
  "gif",
  "svg",
  "tiff",
  "bmp",
  "webp",
];
