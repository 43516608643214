import { useState } from "react";
import i18n from "../i18n";
import { initialTheme } from "../theme/theme";

export const useCustomTheme = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [theme, setTheme] = useState({
    ...initialTheme,
    direction: i18n?.dir ? i18n.dir(i18n.language) : "ltr",
  });

  /**
   * use this hook
   * if you want to customize Theme
   * Change dark/light mode
   * save user's pref etc...
   */
  return { theme };
};
