import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { sideMenu } from "./menu";
import { WithMediaQuery } from "../Hoc/WithMediaQuery";
import { WithMediaQueryProps } from "../Hoc/WithMediaQuery";
import styled from "@emotion/styled";
import { DRAWER_WIDTH } from "../../util/constants";
import { ErtiqaLogo } from "../SVGs/Logo";
import {
  alpha,
  Backdrop,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";

interface SideDrawerProps extends WithMediaQueryProps {
  open: boolean;
  toggleDrawer(): void;
}
const StyledDrawer = styled.div(({ theme }) => ({
  "& .MuiDrawer-root": {
    flexShrink: 0,
    whiteSpace: "nowrap",
    "& .MuiDrawer-paper": {
      background: theme.palette.primary.main,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeInOut,
        duration: 500,
        // delay: 100,
      }),
      overflowX: "hidden",
      width: theme.spacing(8),
      [theme.breakpoints.down("sm")]: {
        width: 0,
      },
      "&.open": {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.easeInOut,
          duration: 500,
          // delay: 25,
        }),
      },
    },
    "& .MuiButtonBase-root": {
      color: "whitesmoke",
      "& svg": {
        fill: "whitesmoke",
      },
      transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1)",
      borderLeft: `0px solid transparent`,
      "&.Mui-selected, &:hover": {
        borderLeft: `4px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        background: alpha(theme.palette.common.white, 0.1),
        "& svg": {
          fill: theme.palette.secondary.main,
        },
      },
    },
    "& .menu_logo": {
      minWidth: DRAWER_WIDTH,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  "& .drawer_backdrop": {
    zIndex: 99,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
const SideDrawer = ({ open, toggleDrawer, smDown }: SideDrawerProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;

  const renderMenu = useMemo(() => {
    return sideMenu.map((item, index) => {
      const selected = item.route === "/" ? pathname === "/" : pathname.startsWith(item.route);
      const Icon = item.Icon;

      return (
        <li key={item.text}>
          <NavLink to={item.route} onClick={() => smDown && toggleDrawer()}>
            <Tooltip
              title={<Typography variant="caption">{t(item.text)}</Typography>}
              enterDelay={0}
              placement="left"
              PopperProps={{ hidden: open }}
            >
              <ListItemButton tabIndex={-1} divider selected={selected}>
                <ListItemIcon>
                  <Icon fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={t(item.text)} />
              </ListItemButton>
            </Tooltip>
          </NavLink>
        </li>
      );
    });
    // eslint-disable-next-line
  }, [pathname, open]);

  return (
    <StyledDrawer>
      <Backdrop
        className="drawer_backdrop"
        open={open}
        transitionDuration={500}
        onClick={toggleDrawer}
      />

      <Drawer
        variant="permanent"
        className={open ? "open" : ""}
        classes={{
          paper: `slim_scrollbar slimmer ${open ? "open" : ""}`,
        }}
        open={open}
      >
        <div className="menu_logo">
          <ErtiqaLogo width={80} height={50} />
        </div>
        <Divider />
        <List component="nav" disablePadding>
          {renderMenu}
        </List>
      </Drawer>
    </StyledDrawer>
  );
};

export default WithMediaQuery(SideDrawer);
