import createCache, { StylisPlugin } from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";

export default function createEmotionCache(dir: "ltr" | "rtl" = "rtl") {
  const key = dir === "rtl" ? "rtlcss" : "css";
  const stylisPlugins = dir === "rtl" ? [rtlPlugin as StylisPlugin] : [];
  const cache = createCache({
    key,
    stylisPlugins,
    // prepend: true
  });
  cache.compat = true;
  return cache;
}
