import { ReactChild, useCallback, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import FullPageSuspense from "../../components/Spinners/FullPageSuspense";
import { useHttp } from "../../hooks/useHttp";
import { SITE_DOMAIN } from "../../util/constants";
import { createCookie } from "../../util/cookies";
import { AuthActions, AuthContext, AuthState } from "./context";

const reducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        init: true,
        ...action.payload,
        isAuth: !!action.payload?.user?.id,
      };
    case "INIT":
      return {
        ...state,
        init: true,
      };
    case "LOGOUT":
      return { init: true, isAuth: false };
    default:
      return state;
  }
};

const AuthStateProvider = ({ children }: { children: ReactChild }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const { fetch, loading, errors } = useHttp();
  const navigate = useNavigate();

  const initAuth = useCallback(async () => {
    try {
      const res = await fetch<Pick<AuthState, "user">>({
        url: "/me",
        urlPrefix: "",
      });
      // See comment in login()
      const isAdmin = !!res.user?.id && res.user.type === "admin";
      if (!isAdmin) {
        window.location.href = SITE_DOMAIN!;
        const customerErr = { response: { data: { message: "You are in the wrong place buddy" } } };
        throw customerErr;
      }

      dispatch({
        type: "LOGIN",
        payload: { user: res.user },
      });
    } catch (error) {
      dispatch({ type: "INIT" });
      throw error;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  const login = async (body: Record<string, string | boolean>) => {
    try {
      const res = await fetch<Pick<AuthState, "user" | "token">>({
        url: "/auth/login",
        urlPrefix: "",
        method: "post",
        body,
      });

      // Guard against user.type !== admin
      // cuz backend surprisingly doesn't know if a customer is logging in
      // or admin. So I need to check it and throw error my self...
      const isAdmin = !!res.user?.id && res.user.type === "admin";
      if (!isAdmin) {
        window.location.href = SITE_DOMAIN!;
        const customerErr = { response: { data: { message: "You are in the wrong place buddy" } } };
        throw customerErr;
      }
      dispatch({
        type: "LOGIN",
        payload: { user: res.user, token: res.token, isAuth: isAdmin },
      });
      createCookie("token", res.token?.access_token || "", 2);
    } catch (error) {
      throw error;
    }
  };
  const logout = async () => {
    try {
      // Clear State
      dispatch({ type: "LOGOUT" });
      // Clear Cookie
      createCookie("token", "", 0);
      navigate("/auth");

      // Need to tell server ?
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      const res = await fetch<{ message: string }>({
        url: "/auth/reset-password",
        urlPrefix: "",
        method: "post",
        body: { email },
      });
      return res.message;
    } catch (error) {
      throw error;
    }
  };

  if (!state.init) {
    return <FullPageSuspense />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loading,
        errors,
        login,
        logout,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthStateProvider;
