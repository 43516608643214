import React from "react";

const List = React.lazy(() => import("../views/Categories/List"));
const AddEdit = React.lazy(() => import("../views/Categories/AddEdit"));

const categoryRoutes = [
  {
    path: "/categories",
    component: List,
  },
  {
    path: "/categories/add",
    component: AddEdit,
  },
  {
    path: "/categories/:id",
    component: AddEdit,
  },
];

export default categoryRoutes;
