import React from "react";
const Generals = React.lazy(() => import("../views/Settings/Settings"));

const settingRoutes = [
  {
    path: "/settings",
    component: Generals,
  },
];

export default settingRoutes;
