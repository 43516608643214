import React from "react";

const List = React.lazy(() => import("../views/Products/List"));
const AddEdit = React.lazy(() => import("../views/Products/AddEdit"));
const View = React.lazy(() => import("../views/Products/View"));

const productRoutes = [
  {
    path: "/products",
    component: List,
  },
  {
    path: "/products/add",
    component: AddEdit,
  },
  {
    path: "/products/:id/edit",
    component: AddEdit,
  },
  {
    path: "/products/:id",
    component: View,
  },
];

export default productRoutes;
