import styled from "@emotion/styled";
import { HomeRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconTextBlock from "../../components/Common/IconTextBlock";

const Section = styled.section(() => ({
  padding: "40px 10px",
}));

const Page404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Section>
      <IconTextBlock
        type="error"
        text={t("common:404_error")}
        onAction={() => navigate("/", { replace: true })}
        actionText={t("common:main")}
        actionIcon={<HomeRounded fontSize="large" />}
      />
    </Section>
  );
};

export default Page404;
