import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import { useAuth } from "./hooks/useAuth";
import { protectedRoutes, publicRoutes } from "./routes";

function App() {
  const { isAuth } = useAuth();

  const routes = isAuth ? protectedRoutes : publicRoutes;

  return (
    <Routes>
      {routes.map((route) => {
        const Component = route.component;
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              isAuth ? (
                <Layout>
                  <Component />
                </Layout>
              ) : (
                <Component />
              )
            }
          />
        );
      })}
    </Routes>
  );
}

export default App;
