import {
  CategoryOutlined,
  Inventory2Outlined,
  LocalOfferOutlined,
  LocationOnOutlined,
  PeopleOutline,
  PieChartOutlineRounded,
  SettingsRounded,
  ShoppingCartOutlined,
  WebRounded,
} from "@mui/icons-material";

export const sideMenu = [
  {
    text: "common:menu.dashboard",
    Icon: PieChartOutlineRounded,
    route: "/",
  },
  {
    text: "common:menu.orders",
    Icon: ShoppingCartOutlined,
    route: "/orders",
  },
  {
    text: "common:menu.categories",
    Icon: CategoryOutlined,
    route: "/categories",
  },
  {
    text: "common:menu.products",
    Icon: Inventory2Outlined,
    route: "/products",
  },
  {
    text: "common:menu.coupons",
    Icon: LocalOfferOutlined,
    route: "/coupons",
  },
  {
    text: "common:menu.pages",
    Icon: WebRounded,
    route: "/pages",
  },
  {
    text: "common:menu.users",
    Icon: PeopleOutline,
    route: "/users",
  },
  {
    text: "common:menu.cities",
    Icon: LocationOnOutlined,
    route: "/cities",
  },
  {
    text: "common:menu.settings",
    Icon: SettingsRounded,
    route: "/settings",
  },
];
