import { createContext } from "react";
import { ErrorResponse } from "../../hooks/useHttp";

export type AuthActions = {
  type: "INIT" | "LOGIN" | "LOGOUT";
  payload?: Partial<AuthState>;
};
export type AuthState = {
  isAuth?: boolean;
  /**Used for detecting initial loading while trying auto auth */
  init?: boolean;
  loading?: boolean;
  errors?: ErrorResponse;
  user?: {
    id: number;
    name: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    type: "admin" | "customer";
    status: string | null;
    photo: string | null;
    is_active: boolean;
    email_verified_at: string;
    created_at: string;
    updated_at: string;
  };
  token?: {
    access_token: string;
  };
};
export interface AuthContextType extends AuthState {
  logout(): Promise<void>;
  login(body: Record<string, string | boolean>): Promise<void>;
  resetPassword(email: string): Promise<string>;
}

export const AuthContext = createContext<AuthContextType>({
  logout: async () => {},
  login: async () => {},
  resetPassword: async () => "",
});
