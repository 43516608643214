import Dashboard from "../views/Dashboard/Dashboard";
import Page404 from "../views/404/404";
import authRoutes from "./auth";
import categoryRoutes from "./categories";
import productRoutes from "./products";
import couponRoutes from "./coupons";
import citiesRoutes from "./shipping";
import orderRoutes from "./orders";
import usersRoutes from "./users";
import pageRoutes from "./pages";
import settingRoutes from "./settings";

export const protectedRoutes = [
  {
    path: "/",
    component: Dashboard,
  },
  ...orderRoutes,
  ...productRoutes,
  ...categoryRoutes,
  ...couponRoutes,
  ...pageRoutes,
  ...usersRoutes,
  ...citiesRoutes,
  ...settingRoutes,
  {
    path: "*",
    component: Page404,
  },
  {
    path: "/auth",
    component: Page404,
  },
];

export const publicRoutes = authRoutes;
