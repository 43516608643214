import { useMediaQuery } from "@mui/material";
import { useCustomTheme } from "../../hooks/useTheme";

export interface WithMediaQueryProps {
  smDown: boolean;
  smUp: boolean;
  mdDown: boolean;
  mdUp: boolean;
}

export function WithMediaQuery<T extends WithMediaQueryProps>(Component: React.ComponentType<T>) {
  const ComponentWithMQ = (props: Omit<T, keyof WithMediaQueryProps>) => {
    const { theme } = useCustomTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const mdUp = useMediaQuery(theme.breakpoints.up("md"));
    return <Component {...(props as T)} smDown={smDown} smUp={smUp} mdDown={mdDown} mdUp={mdUp} />;
  };

  return ComponentWithMQ;
}
