import React from "react";

const List = React.lazy(() => import("../views/Users/List"));
const AddEdit = React.lazy(() => import("../views/Users/AddEdit"));
const View = React.lazy(() => import("../views/Users/View"));

const usersRoutes = [
  {
    path: "/users",
    component: List,
  },
  {
    path: "/users/add",
    component: AddEdit,
  },
  {
    path: "/users/:id/edit",
    component: AddEdit,
  },
  {
    path: "/users/:id",
    component: View,
  },
];

export default usersRoutes;
